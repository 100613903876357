import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DialogService } from 'primeng/api';
import { empty, forkJoin, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CtlsSpinnerService } from 'src/app/Modules/ctls/ctls-shared/ctls-components/ctls-spinner/ctls-spinner.service';
import { CONSTANTS } from 'src/app/Modules/ctls/ctls-shared/ctls-constants/ctls-common-constants';
import {
  CtlsSharedService,
  CtlsSurveyOptionsService,
} from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { CtlsCommonFilterService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-common-filter.service';
import { PopupMessageService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-popup-message.service';
import { CtlsFilterHeaderService } from '../../../../ctls-shared/ctls-services/ctls-filter-header.service';
import { CtlsAddAssignComponent } from '../../presentation/ctls-add-assign/ctls-add-assign.component';
import { CtlsBulkUpdatePopupComponent } from '../../presentation/ctls-bulk-update-popup/ctls-bulk-update-popup.component';
import { CtlsCreateEditTaskPopupComponent } from '../../presentation/ctls-create-edit-task-popup/ctls-create-edit-task-popup.component';
import { CtlsViewSurveyDetailsComponent } from '../../presentation/ctls-view-survey-details/ctls-view-survey-details.component';
import { CtlsTaskListingService } from './../../../../ctls-shared/ctls-services/ctls-task-listing.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-ctls-task-listing',
  templateUrl: './ctls-task-listing.component.html',
  styleUrls: ['./ctls-task-listing.component.scss'],
})
export class CtlsTaskListingComponent implements OnInit, OnDestroy {
  strRadioLabel1 = CONSTANTS.TaskSurveyFeedback.strRadioLabel1;
  strRadioLabel2 = CONSTANTS.TaskSurveyFeedback.strRadioLabel2;
  strDropdown1 = CONSTANTS.TaskSurveyFeedback.strDropdown1;
  strDropdown2 = CONSTANTS.TaskSurveyFeedback.strDropdown2;
  strCtlsTitle = CONSTANTS.TaskListing.strCtlsTitle;
  strTaskListingPermission = CONSTANTS.TaskListing.permissionConstant;
  defaultHeaderFilter:boolean=true;
  openCount: number;
  inprogressCount: number;
  holdCount: number;
  resolvedCount: number;
  closedCount: number;
  reopenedCount: number;
  isFirstSearch = false;
  cardItems = [
    {
      name: CONSTANTS.StatusCards.open,
      colorcss: 'open',
    },
    {
      name: CONSTANTS.StatusCards.inprogress,
      colorcss: 'inprog',
    },
    {
      name: CONSTANTS.StatusCards.hold,
      colorcss: 'hold',
    },
    {
      name: CONSTANTS.StatusCards.resolved,
      colorcss: 'resolved',
    },
    {
      name: CONSTANTS.StatusCards.closed,
      colorcss: 'closed',
    },
    {
      name: CONSTANTS.StatusCards.reopened,
      colorcss: 'reopen',
    },
  ];

  objSurveyName = [];
  strSpan1: string;
  strSpan2: string;
  strTaskListTitle: string;
  showDateFilter: boolean;
  objSurveyInstanceResponse = [];
  objSurveyInstance = [];
  filterData: any;
  filterObj = {
    pageNumber: 1,
    pageSize: 10,
    filterFields: {
      Assignee: "",
      fAMIDs: [],
      bAIDs: [],
      dIVIDs: [],
      contientIDs: [],
      countryIDs: [],
      touchPointIDs: [],
      brandIDs: [],
      branchIDs: [],
      zoneIDs: [],
      influencers: [],
      regionIDs: [],
      salesEngineers: [],
      serviceTechnicians: [],
      customerNames: [],
      customerAccountNumbers: [],
      fromDate: '',
      toDate: '',
      periodType: 'M',
      surveyNameID: null,
      surveyScheduleInstanceID: null,
      distributors: [],
      distributorSegment: [],
      distributorAccountNumber: [],
      surveyID: '',
      contactName: '',
      orderNo: '',
      nPSScore: '',
      nPSComment: '',
      influenceLevel: '',
      emailAddress: '',
      objective: '',
      event: '',
      category: '',
      distributorshipManagerSales: '',
      distributorshipManagerService: '',
      modifiedBy: '',
      modifiedDate: '',
      comments: '',
      taskCount: '',
      cTLSEnabledDate: '',
      cTLSModifiedDate: '',
      feedbackSubmitDate: '',
      firstName: '',
      lastName: '',
      emailID: '',
      designation: '',
      taskID: '',
      priority: '',
      recommendedAction: '',
      selectedCard: '',
      TaskTitle: '',
      Status: '',
      TaskID: '',
      Priority: '',
      RecommendedAction: '',
      status: '',
      Branch: '',
      Zone: '',
      SalesEngineer: '',
      ServiceTechnician: '',
      Distributor: '',
      DistributorSegments: '',
      DistributorAccountNumbers: '',
      FAM: '',
      Touchpoint: '',
      IsNextStep: false,
      surveyInstance: '',
      SubCategory:''
    },
    sortType: '',
    sortField: '',
  };
  commonFilterFieldsObj = // filtering should be applied and passed on when that tab is selected, priority - saved filters.
    {
      BACode: "",
      DivCode: "",
      Country: "",
      FAMCode: "",
      BrandName: "",
      Zone: "",
      Branch: "",
      Influencer: "",
      Continent: "",
    }
  objTaskListingResponse: any;
  selectedRadioOption: number = 1;
  objModifiedColumnResponse = [];
  arrModifiedColumnResponse = ['TaskID'];
  destroy$ = new Subject();
  objCtlsFilterFields: any;
  objGetModifyColumn: any;
  objMasterData: any;
  strSurveyInstance = '';
  strSurveyName = '';
  eventDataLoader: boolean = false;
  blnCreateTaskpopupClose = false;
  objTaskResponse: any;
  copyObjModifiedColumnResponse: any[];
  searchArray = [];
  blnSearchStatusFlag = false;
  objSaveResponse: any;
  CTLSNextStepOptions;
  sequenceModifiedResponse: any;
  NextStep;
  rawTableData: any;
  maxSeqNum: number = 15;
  filterObjWithDateType: any;
  filterObjWithFromToDate: any;
  filterObjWithDateTypeAndFromToDate: any;
  filterObjWithDateTypeAndNullDates: any;
  assignedTask: boolean = true;
  mainArray: any;
  error: boolean;
  selectedRadio: "All";
  radioSelect: "All";

  constructor(
    private ctlsTaskListingService: CtlsTaskListingService,
    private filterService: CtlsFilterHeaderService,
    private surveyOptionsService: CtlsSurveyOptionsService,
    private CtlsSharedService: CtlsSharedService,
    private ref: ChangeDetectorRef,
    private spinnerService: CtlsSpinnerService,
    private dialogService: DialogService,
    private popupMessageService: PopupMessageService,
    public router: Router,
    public route: ActivatedRoute,
    private filterCommonService: CtlsCommonFilterService
  ) {
    const commonFilterFieldsObj = JSON.parse(localStorage.getItem('CTLS_COMMON_FILTERS_TASK_LISTING'));
    if (!commonFilterFieldsObj) {
      localStorage.setItem('CTLS_COMMON_FILTERS_TASK_LISTING', JSON.stringify(this.commonFilterFieldsObj));
    } else {
      this.commonFilterFieldsObj = commonFilterFieldsObj;
    }
    this.filterObjWithDateType = _.cloneDeep(this.filterObj);
    this.filterObjWithDateTypeAndNullDates = _.cloneDeep(this.filterObj);
    this.filterObjWithFromToDate = _.cloneDeep(this.filterObj);
    this.filterObjWithDateTypeAndFromToDate = _.cloneDeep(this.filterObj);
    this.setDefaultFilterObj();

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (!!params.from_date && !!params.to_date) {
          this.filterObj = this.mappingFromFilterDataToObj(this.filterObj, {
            PeriodType: '', StartDate: params.from_date, EndDate: params.to_date
          }, true);
        }
        if (!!params.TaskType) {
          this.selectedRadioOption = parseInt(params.TaskType);
        }
        if (!!params.assigned) {
          this.assignedTask = JSON.parse(params.assigned);
          this.checkAssignedTask({checked: this.assignedTask})
        }
      });
    this.NextStep = this.route.snapshot.queryParams.NextStep;
    this.CTLSNextStepOptions = JSON.parse(
      localStorage.getItem('CTLSNextStepOptions')
    );
  }

  /**
   * On Init
   */
  ngOnInit() {
    this.selectedRadio= "All";
    this.radioSelect= "All";
    this.isFirstSearch = true;
    // this.objMasterData = await this.getMasterFilterData();
    this.spinnerService.show();
    this.popupMessageService //To get data from edit task pop
      .getMessage()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((data) => this.savePopupData(data)) //call save api
      )
      .subscribe((res) => {
        if (!!res) {
          this.objTaskResponse = res;
        }
      });
    setTimeout(() => {
      this.filterCommonService
        .getMasterData()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (!!data) {
            this.objMasterData = data;
          }
        });
      this.getNextStepData();
      // this.spinnerService.hide();
    }, 0);
  }
  getNextStepData() {
    if (this.NextStep === 'true' && !!this.CTLSNextStepOptions) {
      // When user clicks on the Next Step from the Enable/Disable screen,
      // system should redirect to the task listing screen and display the feedbacks
      // against the enabled Survey. The displaying of the data will be based on the
      // combination of Survey, FAM, BA, DIV & Brand for T survey and
      // Survey, Instance, FAM, BA & Brand for R survey

      this.selectedRadioOption = this.CTLSNextStepOptions.selectedRadioOption;
      this.filterData = this.CTLSNextStepOptions.filterData;
      this.filterObj.filterFields.surveyNameID = this.CTLSNextStepOptions
        .surveyOptions.SurveyID
        ? this.CTLSNextStepOptions.surveyOptions.SurveyID
        : null;
      this.filterObj.filterFields.surveyScheduleInstanceID = this
        .CTLSNextStepOptions.surveyOptions.Instance
        ? this.CTLSNextStepOptions.surveyOptions.Instance
        : null;
      switch (this.selectedRadioOption) {
        case 1: {
          this.objCtlsFilterFields = this.ctlsTaskListingService.filterObjForTransactional;
          this.showDateFilter = this.ctlsTaskListingService.filterObjForTransactional.showDateFilter;
          this.filterService.filterObjForGrid = this.ctlsTaskListingService.filterObjForTransactional.filterObjForGrid;

          break;
        }
        case 2: {
          this.objCtlsFilterFields = this.ctlsTaskListingService.filterObjForRelational;
          this.showDateFilter = this.ctlsTaskListingService.filterObjForRelational.showDateFilter;
          this.filterService.filterObjForGrid = this.ctlsTaskListingService.filterObjForRelational.filterObjForGrid;
          break;
        }
        default: {
          break;
        }
      }
      this.filterObj = this.mappingFromFilterDataToObj(
        this.filterObj,
        this.CTLSNextStepOptions.filterData
      );
      this.filterObj.filterFields.IsNextStep = true;

      this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
    } else {
      this.getFilterFields(this.selectedRadioOption);
    }
  }

  onRadioClick(type) {
    this.radioSelect = type;
    setTimeout(() => {
    this.selectedRadio = type; 
    this.ref.detectChanges(); 
    if (type === "All") {
      this.filterObj.filterFields.SubCategory = ''; 
    } else {
      this.filterObj.filterFields.SubCategory = type === 'OEM' ? 'OEM' : 'DIST';
    }
    this.getTaskListingTable(this.selectedRadioOption, this.filterObj); 
  }, 100);
  }
  /**
    * Set default values for checking filterObj
    */
  setDefaultFilterObj() {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;
    var StartDate = this.changeDateFormate(firstDay);
    var EndDate = this.changeDateFormate(lastDay);

    this.filterObjWithDateTypeAndNullDates.filterFields.fromDate = null;
    this.filterObjWithDateTypeAndNullDates.filterFields.toDate = null;

    this.filterObjWithFromToDate.filterFields.fromDate = StartDate;
    this.filterObjWithFromToDate.filterFields.toDate = EndDate;
    this.filterObjWithFromToDate.filterFields.periodType = "";

    this.filterObjWithDateTypeAndFromToDate.filterFields.fromDate = StartDate;
    this.filterObjWithDateTypeAndFromToDate.filterFields.toDate = EndDate;
  }
  private changeDateFormate(date) {
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }
  /**
   * save edit task details with file
   * @param objSaveTask
   */
  savePopupData(objSaveTask) {
    if (!!objSaveTask && objSaveTask.operation === 'SAVE_TASK') {
      const req = objSaveTask.data;
      const uplodedFiles = objSaveTask.uplodedFiles;
      const frmData = new FormData();
      frmData.append('data', JSON.stringify(req));
      if (uplodedFiles) {
        for (var i = 0; i < uplodedFiles.length; i++) {
          frmData.append('files', uplodedFiles[i]);
        }
      }
      return this.ctlsTaskListingService.SaveTaskManagementDetails(frmData);
    } else {
      return empty();
    }
  }

  /**
   * load master filter data
   */
  getMasterFilterData() {
    return this.filterService.getAllData();
  }

  /**load survey options
   * @param data
   */
  getSurveyOptions(data) {
    this.surveyOptionsService
      .GetOptions(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        if (!!response.Response) {
          let arrSurveyNames = [];
          response.Response.map((item: any) => {
            arrSurveyNames.push({
              SurveyScheduleInstanceID: item.SurveyScheduleInstanceID,
              code: item.SurveyNameID,
              name: item.SurveyName,
            });
            arrSurveyNames = _.uniqBy(arrSurveyNames, 'code');
            this.objSurveyName = arrSurveyNames;
          });
          if (this.selectedRadioOption == 2) {
            let arrSurveyInstances = [];
            response.Response.map((item: any) => {
              arrSurveyInstances.push({
                SurveyNameID: item.SurveyNameID,
                code: item.SurveyScheduleInstanceID,
                name: item.surveyInstance,
              });
              this.objSurveyInstance = arrSurveyInstances;
              this.objSurveyInstanceResponse = arrSurveyInstances;
            });
          }
        }
      });
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    // localStorage.removeItem('CTLSNextStepOptions');
    this.destroy$.next();
    this.destroy$.complete();
    this.filterService.resetGlobalFilterData();
  }

  checkAssignedTask(event) {
    this.assignedTask = event.checked;
    this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
  }
  /**
   * Get Task Listing Data from API Service
   * @param value: number
   * @param filterObj
   */
  private getTaskListingTable(value = 1, objFilter) {
    let isEqual = false;
    //checking if the table data is a filtered/searched data or unfiltered one.
    if (
      _.isEqual(this.filterObjWithDateType, objFilter) ||
      _.isEqual(this.filterObjWithDateTypeAndNullDates, objFilter) ||
      _.isEqual(this.filterObjWithFromToDate, objFilter) ||
      _.isEqual(this.filterObjWithDateTypeAndFromToDate, objFilter)
    ) {
      isEqual = true;
    }

    this.spinnerService.show();
    if (this.assignedTask) {
      var modfiyObj = {
        GridName: 'CTLS_TASK_LISTING',
        SubCategoryCode: this.selectedRadioOption === 1 ? 'TRANS' : 'DIST',
      };
    } else {
      var modfiyObj = {
        GridName: 'CTLS_TASK_LISTING_UNASSIGNED',
        SubCategoryCode: this.selectedRadioOption === 1 ? 'TRANS' : 'DIST',
      };
    }
    let api1 = this.ctlsTaskListingService.getTaskListing(this.assignedTask, value, objFilter);
    let api2 = this.ctlsTaskListingService.GetModifyColumns(modfiyObj);

    forkJoin([api1, api2])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (results) => {
          if(results[0].Status =='SUCCESS') {
            this.error = false;
            if (!this.assignedTask) {
              results[0].Response.ItemsHeader = results[0].Response.ItemsHeaders
            }
            this.rawTableData = results[0].Response;
            if (!isEqual) {
              this.rawTableData.IsFiltered = true;
            } else
              this.rawTableData.IsFiltered = false;
            this.modifyResponseWithSelectedCard(
              results[0].Response,
              this.filterObj.filterFields.selectedCard
            );
          } else {
            this.objTaskListingResponse = null;
            this.error = true;
          }
          
          this.objGetModifyColumn = results[1];

          if (
            !!this.objTaskListingResponse &&
            !!this.objTaskListingResponse.TaskCountResponse
          ) {
            const countResponse = this.objTaskListingResponse.TaskCountResponse;
            if (!!countResponse) {
              this.openCount = countResponse.OpenTaskCount;
              this.inprogressCount = countResponse.InProgressTaskCount;
              this.holdCount = countResponse.HoldTaskCount;
              this.resolvedCount = countResponse.ResolvedTaskCount;
              this.closedCount = countResponse.ClosedTaskCount;
              this.reopenedCount = countResponse.ReopenTaskCount;
            }
          }

          if (!!this.objGetModifyColumn.Response) {
            const strModifiedColumnResponse = this.objGetModifyColumn.Response;
            this.arrModifiedColumnResponse =
              strModifiedColumnResponse.split(',');

            this.modifiedColumnResponse(this.arrModifiedColumnResponse, false);
          } else {
            if (value === 1) {
             if(!!this.assignedTask){
              this.modifiedColumnResponse([
                'CreatedDate',
                'ModifiedDate',
                'ModifiedBy',
                'Priority',
                'Brand',
                'NPSScore',
                'Assignee',
              ]);
             }else{
              this.modifiedColumnResponse([
                'CreatedDate',
                'RecommendedAction',
                'Priority',
                'NPSScore',
                'DIV',
                'Brand',
                           ]);
             }
            } else if (value === 2) {
              if(!!this.assignedTask){
                this.modifiedColumnResponse([
                  'CreatedDate',
                  'ModifiedDate',
                  'ModifiedBy',
                  'Priority',
                  'Brand',
                  'NPSScore',
                  'Assignee',
                ]);
              } else {
                this.modifiedColumnResponse([
                  'CreatedDate',
                  'RecommendedAction',
                  'Priority',
                  'NPSScore',
                  'DIV',
                  'Brand',
                                 
                 ]);
              }
            } else if (value === 2) {
              if (!!this.assignedTask) {
                this.modifiedColumnResponse([
                  'CreatedDate',
                  'ModifiedDate',
                  'ModifiedBy',
                  'Priority',
                  'Brand',
                  'NPSScore',
                  'Assignee',
                ]);
              } else {
                this.modifiedColumnResponse([
                  'CreatedDate',
                  'RecommendedAction',
                  'Priority',
                  'NPSScore',
                  'DIV',
                  'Brand',
                ]);
              }
            }
          }
        },
        () => { },
        () => {
          this.spinnerService.hide();
          // this.filterObj.filterFields.IsNextStep = false;
          // localStorage.removeItem('CTLSNextStepOptions');
          // this.CTLSNextStepOptions = null;
        }
      );
  }

  modifiedColumnResponse(arrModifiedColumnResponse, blnSave = false) {
    this.copyObjModifiedColumnResponse = [];
    if (blnSave == false) this.objModifiedColumnResponse = [];
    this.arrModifiedColumnResponse = arrModifiedColumnResponse;

    if (!!arrModifiedColumnResponse.length) {
      arrModifiedColumnResponse.forEach((element) => {
        if (
          !!this.objTaskListingResponse &&
          !!this.objTaskListingResponse.ItemsHeader &&
          !!this.objTaskListingResponse.ItemsHeader.Headers
        ) {
          if (blnSave == false) {
            this.objModifiedColumnResponse.push(
              this.objTaskListingResponse.ItemsHeader.Headers.find(
                (o) => o.field === element
              )
            );
          }
          this.copyObjModifiedColumnResponse.push(
            this.objTaskListingResponse.ItemsHeader.Headers.find(
              (o) => o.field === element
            )
          );
        }
      });
    }
  }

  /** save modified column
   * @param data
   * */
  saveModifyColumn(data) {
    this.modifiedColumnResponse(data.ColumnNames.split(','), true);
    this.CtlsSharedService.SaveModifyColumns(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((modifyColumnResponse) => {
        // this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        // this.ref.detectChanges();
      });
  }

  mappingFromFilterDataToObj(filterObj, filterData, haveDate = false) {
    if (!haveDate) {
      filterObj.filterFields.bAIDs = filterData.BACode
        ? filterData.BACode.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.branchIDs = filterData.Branch
        ? filterData.Branch.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.brandIDs = filterData.BrandName
        ? filterData.BrandName.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.contientIDs = filterData.Continent
        ? filterData.Continent.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.countryIDs = filterData.Country
        ? filterData.Country.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.customerAccountNumbers = filterData.CustomerAccountNumber
        ? filterData.CustomerAccountNumber.split('|').map((x) => x)
        : [];
      filterObj.filterFields.customerNames = filterData.CustomerName
        ? filterData.CustomerName.split('|').map((x) => x)
        : [];
      filterObj.filterFields.dIVIDs = filterData.DivCode
        ? filterData.DivCode.split('|').map((x) => +x)
        : [];
      let distribitorAccNum =  filterData.DistributorAccountNumbers ?  filterData.DistributorAccountNumbers :  filterData.DistributorAccountNumber
      filterObj.filterFields.distributorAccountNumber = distribitorAccNum
        ? distribitorAccNum.split('|').map((x) => x)
        : [];
      filterObj.filterFields.distributorSegment = filterData.DistributorSegment
        ? filterData.DistributorSegment.split('|').map((x) => x)
        : [];
      filterObj.filterFields.distributors = filterData.Distributors
        ? filterData.Distributors.split('|').map((x) => x)
        : [];
      filterObj.filterFields.fAMIDs = filterData.FAMCode
        ? filterData.FAMCode.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.influencers = filterData.Influencer
        ? filterData.Influencer.split('|').map((x) => x)
        : [];
      filterObj.filterFields.SubCategory = filterData.SubCategory
        ? filterData.SubCategory
        : null;
      if (filterData.PeriodType || filterData.StartDate || filterData.EndDate) {
        filterObj.filterFields.periodType = filterData.PeriodType
          ? filterData.PeriodType
          : '';
        filterObj.filterFields.fromDate = filterData.StartDate
          ? filterData.StartDate
          : '';
        filterObj.filterFields.toDate = filterData.EndDate
          ? filterData.EndDate
          : '';
      }
      filterObj.filterFields.regionIDs = filterData.Region
        ? filterData.Region.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.salesEngineers = filterData.SalesEngineer
        ? filterData.SalesEngineer.split('|').map((x) => x)
        : [];
      filterObj.filterFields.serviceTechnicians = filterData.ServiceTechnician
        ? filterData.ServiceTechnician.split('|').map((x) => x)
        : [];
      filterObj.filterFields.touchPointIDs = filterData.Touchpoint
        ? filterData.Touchpoint.split('|').map((x) => +x)
        : [];
      filterObj.filterFields.zoneIDs = filterData.Zone
        ? filterData.Zone.split('|').map((x) => +x)
        : [];
    } else if (haveDate) {
      if (filterData.PeriodType || filterData.StartDate || filterData.EndDate) {
        filterObj.filterFields.periodType = filterData.PeriodType
          ? filterData.PeriodType
          : '';
        filterObj.filterFields.fromDate = filterData.StartDate
          ? filterData.StartDate
          : '';
        filterObj.filterFields.toDate = filterData.EndDate
          ? filterData.EndDate
          : '';
      }
    }
    return filterObj;
  }
  /**
* clear common filter fileds in task listing screen
* clear obj from session storage to current filter obj // CTLS_COMMON_FILTERS_TASK_LISTING
*/
  clearCommonFilterFields() {
    // clear filterData
    this.filterData.BACode = '';
    this.filterData.Continent = '';
    this.filterData.BrandName = '';
    this.filterData.Country = '';
    this.filterData.FAMCode = '';
    this.filterData.DivCode = '';
    this.filterData.Zone = '';
    this.filterData.Branch = '';
    this.filterData.Influencer = '';
    this.filterData.SubCategory = '';
    this.commonFilterFieldsObj.BACode = '';
    this.commonFilterFieldsObj.Continent = '';
    this.commonFilterFieldsObj.BrandName = '';
    this.commonFilterFieldsObj.Country = '';
    this.commonFilterFieldsObj.FAMCode = '';
    this.commonFilterFieldsObj.DivCode = '';
    this.commonFilterFieldsObj.Zone = '';
    this.commonFilterFieldsObj.Branch = '';
    this.commonFilterFieldsObj.Influencer = '';

    localStorage.setItem('CTLS_COMMON_FILTERS_TASK_LISTING', JSON.stringify(this.commonFilterFieldsObj))
  }
  /**
     * seting common filter fileds in task listing screen
     * mapping obj from session storage to current filter obj // CTLS_COMMON_FILTERS_TASK_LISTING
     */
  setCommonFilterFields(appliedFilterDataObj) {
    this.commonFilterFieldsObj.BACode = appliedFilterDataObj.BACode
    this.commonFilterFieldsObj.Continent = appliedFilterDataObj.Continent
    this.commonFilterFieldsObj.BrandName = appliedFilterDataObj.BrandName
    this.commonFilterFieldsObj.Country = appliedFilterDataObj.Country
    this.commonFilterFieldsObj.FAMCode = appliedFilterDataObj.FAMCode
    this.commonFilterFieldsObj.DivCode = appliedFilterDataObj.DivCode
    this.commonFilterFieldsObj.Zone = appliedFilterDataObj.Zone
    this.commonFilterFieldsObj.Branch = appliedFilterDataObj.Branch
    this.commonFilterFieldsObj.Influencer = appliedFilterDataObj.Influencer
    localStorage.setItem('CTLS_COMMON_FILTERS_TASK_LISTING', JSON.stringify(this.commonFilterFieldsObj))
  }
  /**
  * mappingommon filter fileds in task listing screen
  * mapping obj from common filter to current filter obj // CTLS_COMMON_FILTERS_TASK_LISTING
  */
  mapCommonFilterFields(appliedFilterDataObj) {
    // get saved filter from session storage
    const savedFilter = JSON.parse(localStorage.getItem(this.objCtlsFilterFields.savedFilterSessionStorageName));
    if (savedFilter.BACode || savedFilter.DivCode || savedFilter.Zone || savedFilter.Branch || savedFilter.Influencer || savedFilter.Continent || savedFilter.BrandName || savedFilter.Country || savedFilter.FAMCode) {
      appliedFilterDataObj.BACode = savedFilter.BACode;
      appliedFilterDataObj.Continent = savedFilter.Continent;
      appliedFilterDataObj.BrandName = savedFilter.BrandName;
      appliedFilterDataObj.Country = savedFilter.Country;
      appliedFilterDataObj.FAMCode = savedFilter.FAMCode;
      appliedFilterDataObj.DivCode = savedFilter.DivCode;
      appliedFilterDataObj.Zone = savedFilter.Zone;
      appliedFilterDataObj.Branch = savedFilter.Branch;
      appliedFilterDataObj.Influencer = savedFilter.Influencer;
    } else {
      appliedFilterDataObj.BACode = this.commonFilterFieldsObj.BACode;
      appliedFilterDataObj.Continent = this.commonFilterFieldsObj.Continent;
      appliedFilterDataObj.BrandName = this.commonFilterFieldsObj.BrandName;
      appliedFilterDataObj.Country = this.commonFilterFieldsObj.Country;
      appliedFilterDataObj.FAMCode = this.commonFilterFieldsObj.FAMCode;
      appliedFilterDataObj.DivCode = this.commonFilterFieldsObj.DivCode;
      appliedFilterDataObj.Zone = this.commonFilterFieldsObj.Zone;
      appliedFilterDataObj.Branch = this.commonFilterFieldsObj.Branch;
      appliedFilterDataObj.Influencer = this.commonFilterFieldsObj.Influencer;
    }
    return appliedFilterDataObj;
  }
  checkSearchFilters() {
    if (this.searchArray.length == 0) {
      this.blnSearchStatusFlag = false;
    } else {
      this.blnSearchStatusFlag = true;
    }
  }

  /**
   * All event comming from presentation handles here
   * @param event
   */
  allEvents(event) {
    switch (event.operation) {
      case 'FILTER_SUBMIT': {
        const filterFromSessionStorageWithDate = JSON.parse(
          localStorage.getItem(this.objCtlsFilterFields.sessionStorageName)
        );
        if (filterFromSessionStorageWithDate && filterFromSessionStorageWithDate != null) {
          event.data.EndDate = filterFromSessionStorageWithDate.EndDate;
          event.data.PeriodType = filterFromSessionStorageWithDate.PeriodType;
          event.data.StartDate = filterFromSessionStorageWithDate.StartDate;
        }
        localStorage.setItem(
          this.objCtlsFilterFields.sessionStorageName,
          JSON.stringify(event.data)
        );
        this.setCommonFilterFields(event.data);
        this.filterObj.filterFields.surveyNameID = null;
        this.filterObj.filterFields.surveyScheduleInstanceID = null;
        this.filterObj.filterFields.SubCategory = null;
        this.checkSearchFilters();
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          event.data
        );
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        break;
      }
      case 'FILTER_MASTER_SAVE': {
        localStorage.setItem(
          this.objCtlsFilterFields.sessionStorageName,
          JSON.stringify(event.data)
        );
        localStorage.setItem( // store saved filter in session storage
          this.objCtlsFilterFields.savedFilterSessionStorageName,
          JSON.stringify(event.data)
        );
        this.filterObj.filterFields.surveyNameID = null;
        this.filterObj.filterFields.surveyScheduleInstanceID = null;
        this.filterObj.filterFields.SubCategory = null;
        this.checkSearchFilters();
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          event.data
        );
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        break;
      }
      case 'FILTER_TOGGLE_PIN': {
        break;
      }
      case 'FILTER_DATE_SUBMIT': {
        // only passing date params from filter . so first get full object from session storage and only apply date params
        const filterFromSessionStorage = JSON.parse(
          localStorage.getItem(this.objCtlsFilterFields.sessionStorageName)
        );
        if (filterFromSessionStorage && filterFromSessionStorage != null) {
          filterFromSessionStorage.EndDate = event.data.EndDate;
          filterFromSessionStorage.PeriodType = event.data.PeriodType;
          filterFromSessionStorage.StartDate = event.data.StartDate;
          localStorage.setItem(
            this.objCtlsFilterFields.sessionStorageName,
            JSON.stringify(filterFromSessionStorage)
          );
        }
        this.filterObj.filterFields.surveyNameID = null;
        this.filterObj.filterFields.surveyScheduleInstanceID = null;
        this.filterObj.filterFields.SubCategory = null;
        this.checkSearchFilters();
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          event.data,
          true // only for date submit
        );
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        break;
      }
      case 'CLEAR_SAVED_FILTER': {
        localStorage.setItem(
          this.objCtlsFilterFields.sessionStorageName,
          JSON.stringify(event.data)
        );
        localStorage.setItem( // store saved filter in session storage
          this.objCtlsFilterFields.savedFilterSessionStorageName,
          JSON.stringify(event.data)
        );
        this.clearCommonFilterFields()
        this.checkSearchFilters();
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          event.data
        );
        break;
      }
      case 'GET_TASK_LIST': {
        this.searchArray = event.searchArray;
        this.checkSearchFilters();

        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          event.data.filterFields
        );

        //this.filterObj = event.data;
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        break;
      }
      case 'MODIFY_COLUMN': {
        this.checkSearchFilters();
        this.saveModifyColumn(event.data);
        break;
      }
      case 'CREATE_TASK': {
        this.openEditTask();
        // this.openDialog(event.data);
        break;
      }
      case 'VIEW_SURVEY_DETAILS': {
        if (this.selectedRadioOption === 1) {
          this.ctlsTaskListingService
            .ViewTransactionalSurveyDetailsById(event.data.objData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              this.openViewDialog(res);
            });
        }
        if (this.selectedRadioOption === 2) {
          this.ctlsTaskListingService
            .ViewRelationshipSurveyDetailsById(event.data.objData)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
              this.openViewDialog(res);
            });
        }
        break;
      }
      case 'RADIO_CHANGE': {
        //this.resetFilters();      
        this.selectedRadioOption = event.data;
        if( this.selectedRadioOption == 2) {
          this.objCtlsFilterFields = this.ctlsTaskListingService.filterObjForRelational;
        } else {
          this.objCtlsFilterFields = this.ctlsTaskListingService.filterObjForTransactional;
        }
        //this.getFilterFields(this.selectedRadioOption);
        const filterFromSessionStorage = JSON.parse(
          localStorage.getItem(this.objCtlsFilterFields.sessionStorageName)
        );
        if (filterFromSessionStorage && filterFromSessionStorage != null) {
          // filterFromSessionStorage.EndDate = event.data.EndDate;
          // filterFromSessionStorage.PeriodType = event.data.PeriodType;
          // filterFromSessionStorage.StartDate = event.data.StartDate;
          localStorage.setItem(
            this.objCtlsFilterFields.sessionStorageName,
            JSON.stringify(filterFromSessionStorage)
          );
        }
        this.filterObj.filterFields.surveyNameID = null;
        this.filterObj.filterFields.surveyScheduleInstanceID = null;
        this.filterObj.filterFields.SubCategory = null;
        this.checkSearchFilters();
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          event.data,
          true // only for date submit
        );
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        // this.blnSearchStatusFlag = false;
        // this.strSurveyInstance = '';
        // this.strSurveyName = '';
        // this.selectedRadioOption = event.data;
        // this.eventDataLoader = false;
        // this.resetFilters();
        // this.getSurveyOptions(this.selectedRadioOption);
        // this.getFilterFields(this.selectedRadioOption);
        break;
      }
      case 'DROPDOWN_CHANGE_1': {
        this.checkSearchFilters();
        let surveyname;
        let surveyNameID;
        if (event.data) {
          surveyname = event.data ? event.data.name : '';
          surveyNameID = event.data ? event.data.code : null;
        } else {
          surveyname = '';
          surveyNameID = null;
        }
        this.strSurveyName = surveyname;
        this.strSurveyInstance = '';
        this.filterObj.filterFields.surveyNameID = surveyNameID;
        this.filterObj.filterFields.surveyScheduleInstanceID = null;
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);

        this.getFilterInstances(event.data);
        break;
      }
      case 'DROPDOWN_CHANGE_2': {
        this.checkSearchFilters();
        let surveyinstance;
        let surveyScheduleInstanceID;
        if (event.data) {
          surveyinstance = event.data ? event.data.name : '';
          surveyScheduleInstanceID = event.data ? event.data.code : null;
        } else {
          surveyinstance = '';
          surveyScheduleInstanceID = null;
        }
        this.strSurveyInstance = surveyinstance;
        this.filterObj.filterFields.surveyScheduleInstanceID = surveyScheduleInstanceID;
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        break;
      }
      case 'BULK_UPDATE': {
        this.showBulkUpdate();
        break;
      }
      case 'DELETE_TASKS': {
        this.ctlsTaskListingService
          .DeleteTasks(event.data)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (!!res) {
              this.objSaveResponse = res;
              this.getTaskListingTable(
                this.selectedRadioOption,
                this.filterObj
              );
            }
          });
      }
      case 'GET_UPDATED_TASK_LIST': {
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        break;
      }
      case 'MODIFY_ROW_SEQUENCE': {
        this.modifyRowSequence(
          this.rowSequencing(event.data.seqType, event.data.selectedRows),
          event.data.seqType
        );
        break;
      }
      case 'GET_PINNED_TASK_LIST': {
        this.modifyResponseWithSelectedCard(
          this.rawTableData,
          this.filterObj.filterFields.selectedCard
        );
        break;
      }
      case 'UNPIN_TASK': {
        this.unpinTask(event.data, event.isOnlyUnpin);
        break;
      }
      case 'PIN_UNPIN_TASK': {
        this.pinUnpinTask(event.data);
        break;
      }
      case 'CREATE_ASSIGN': {
        this.openDialogAssign({ ...event.data, mode: this.selectedRadioOption });
        break;
      }
      case 'UPDATE_CLOCK': {
        this.updateClockTimings(event.data);
        break;
      }
      default:
        break;
    }
  }

  /** Update the row resolve within time to latest
   * @param data
   */
  updateClockTimings(TaskID) {
    this.spinnerService.show();
    this.ctlsTaskListingService
      .getUpdatedTime(TaskID)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!!res.Response) {
          this.rawTableData.Items.map((item) => {
            if (item.TaskID === TaskID) {
              item.IsBanned = res.Response.IsBanned
              item.IsNegativeTime = res.Response.IsNegativeTime
              item.ResolveWithin = res.Response.ResolveWithin
            }
          })
        }
        this.modifyResponseWithSelectedCard(
          this.rawTableData,
          this.filterObj.filterFields.selectedCard
        );
      }, () => {
        this.spinnerService.hide();
      }, () => {
        this.spinnerService.hide();
      })
  }
  /** open popup for create task
 * @param data
 */
  openDialogAssign(data) {
    const ref = this.dialogService.open(CtlsAddAssignComponent, {
      width: '40vw',
      height: '60vh',
      transitionOptions: '0ms',
      data: data,
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((resObj) => {
      if (resObj) {
        if (resObj != null) {
          this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
          this.objSaveResponse = resObj;
        }
      }
    });
  }
  /**
   * Open edit task module
   */
  openEditTask() {
    this.router.navigate(['ctls/task-feedback/task-listing/edit']);
  }

  /**Filter instances based on survey
   * @param data
   */
  getFilterInstances(data) {
    if (data) {
      this.objSurveyInstance = _.filter(
        this.objSurveyInstanceResponse,
        (item) => {
          return item.SurveyNameID === data.code;
        }
      );
    } else {
      this.objSurveyInstance = this.objSurveyInstanceResponse;
    }
  }
  /**load survey filter fields
   *
   */
  getFilterFields(surveyType) {
    switch (surveyType) {
      case 1: {
        this.objCtlsFilterFields = this.ctlsTaskListingService.filterObjForTransactional;
        this.showDateFilter = this.ctlsTaskListingService.filterObjForTransactional.showDateFilter;
        this.filterService.filterObjForGrid = this.ctlsTaskListingService.filterObjForTransactional.filterObjForGrid;
        break;
      }
      case 2: {
        this.objCtlsFilterFields = this.ctlsTaskListingService.filterObjForRelational;
        this.showDateFilter = this.ctlsTaskListingService.filterObjForRelational.showDateFilter;
        this.filterService.filterObjForGrid = this.ctlsTaskListingService.filterObjForRelational.filterObjForGrid;
        break;
      }
      default: {
        break;
      }
    }
    if (this.objCtlsFilterFields) {
      const filterFromSessionStorage = JSON.parse(
        localStorage.getItem(this.objCtlsFilterFields.sessionStorageName)
      );
      const commonFilterFromSessionStorage = JSON.parse(
        localStorage.getItem('CTLS_COMMON_FILTERS_TASK_LISTING')
      );
      this.commonFilterFieldsObj = commonFilterFromSessionStorage;
      if (filterFromSessionStorage && filterFromSessionStorage != null) {
        this.filterData = filterFromSessionStorage;
        if (this.filterObj.filterFields.fromDate != '' && this.filterObj.filterFields.toDate != '') {
          // this.filterData.PeriodType = '';
          // this.filterData.StartDate = this.filterObj.filterFields.fromDate;
          // this.filterData.EndDate = this.filterObj.filterFields.toDate;
        }
        this.filterData = this.mapCommonFilterFields(this.filterData);// mapping common filter and saved filters
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          this.filterData
        );
        this.filterObj = this.mappingFromFilterDataToObj(
          this.filterObj,
          this.filterData,
          true
        );
        this.getTaskListingTable(this.selectedRadioOption, this.filterObj);
        this.ref.detectChanges();
      } else {
        this.filterService
          .getUserFilter(
            this.objCtlsFilterFields.surveySubCategoryCode,
            this.objCtlsFilterFields.screenCode
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            if (!!res && !!res.Response) {
              this.filterData = res.Response;
              localStorage.setItem(
                this.objCtlsFilterFields.sessionStorageName,
                JSON.stringify(this.filterData)
              );
              localStorage.setItem( // store saved filter in session storage
                this.objCtlsFilterFields.savedFilterSessionStorageName,
                JSON.stringify(this.filterData)
              );
              this.filterData = this.mapCommonFilterFields(this.filterData); // mapping common filter and saved filters
              this.filterObj = this.mappingFromFilterDataToObj(
                this.filterObj,
                this.filterData
              );
              this.getTaskListingTable(
                this.selectedRadioOption,
                this.filterObj
              );
              this.ref.detectChanges();
            }
          });
      }
    }
  }

  /**
   * Count Card Selection
   * @param value : string
   */
  selectedCard(event, value: string = '') {
    if (event.target.checked) {
      this.filterObj.filterFields.selectedCard = value;
    }
    // else {
    //   this.filterObj.filterFields.selectedCard = '';
    // }
    this.modifyResponseWithSelectedCard(this.rawTableData, value);
  }
  /**
   * Control for filtering table data based on selected card
   * @param rawData : Object
   * @param value : string ; optional
   * create new object using copy by value or deep cloning , so that changes
   * like sorting etc. in presentation table does not reflect in rawTableData in the container
   */
  modifyResponseWithSelectedCard(rawData, card) {
    let arrayObj = _.cloneDeep(rawData);
    switch (card) {
      case 'OPEN': {
        arrayObj.Items = _.filter(arrayObj.Items, ['Status', 'Open']);
        this.objTaskListingResponse = arrayObj;
        break;
      }
      case 'INPROGRESS': {
        arrayObj.Items = _.filter(arrayObj.Items, ['Status', 'In Progress']);
        this.objTaskListingResponse = arrayObj;
        break;
      }
      case 'HOLD': {
        arrayObj.Items = _.filter(arrayObj.Items, ['Status', 'Hold']);
        this.objTaskListingResponse = arrayObj;
        break;
      }
      case 'RESOLVED': {
        arrayObj.Items = _.filter(arrayObj.Items, ['Status', 'Resolved']);
        this.objTaskListingResponse = arrayObj;
        break;
      }
      case 'CLOSED': {
        arrayObj.Items = _.filter(arrayObj.Items, ['Status', 'Closed']);
        this.objTaskListingResponse = arrayObj;
        break;
      }
      case 'REOPENED': {
        arrayObj.Items = _.filter(arrayObj.Items, ['Status', 'Reopened']);
        this.objTaskListingResponse = arrayObj;
        break;
      }
      default: {
        this.objTaskListingResponse = arrayObj;
        break;
      }
    }
  }

  /**
   * Reset Count Card Selection
   */
  resetCardSelection() {
    this.filterObj.filterFields.selectedCard = '';
    this.modifyResponseWithSelectedCard(this.rawTableData, '');
  }

  /**ResetFilters for radio change  */
  resetFilters() {
    this.filterObj = {
      pageNumber: 1,
      pageSize: 10,
      filterFields: {
        Assignee: "",
        fAMIDs: [],
        bAIDs: [],
        dIVIDs: [],
        contientIDs: [],
        countryIDs: [],
        touchPointIDs: [],
        brandIDs: [],
        branchIDs: [],
        zoneIDs: [],
        influencers: [],
        regionIDs: [],
        salesEngineers: [],
        serviceTechnicians: [],
        customerNames: [],
        customerAccountNumbers: [],
        fromDate: null,
        toDate: null,
        periodType: 'M',
        surveyNameID: null,
        surveyScheduleInstanceID: null,
        distributors: [],
        distributorSegment: [],
        distributorAccountNumber: [],
        surveyID: '',
        contactName: '',
        orderNo: '',
        nPSScore: '',
        nPSComment: '',
        influenceLevel: '',
        emailAddress: '',
        objective: '',
        event: '',
        category: '',
        distributorshipManagerSales: '',
        distributorshipManagerService: '',
        modifiedBy: '',
        modifiedDate: '',
        comments: '',
        taskCount: '',
        cTLSEnabledDate: '',
        cTLSModifiedDate: '',
        feedbackSubmitDate: '',
        firstName: '',
        lastName: '',
        emailID: '',
        designation: '',
        taskID: '',
        priority: '',
        recommendedAction: '',
        selectedCard: '',
        TaskTitle: '',
        Status: '',
        TaskID: '',
        Priority: '',
        RecommendedAction: '',
        status: '',
        Branch: '',
        Zone: '',
        SalesEngineer: '',
        ServiceTechnician: '',
        Distributor: '',
        DistributorSegments: '',
        DistributorAccountNumbers: '',
        FAM: '',
        Touchpoint: '',
        IsNextStep: false,
        surveyInstance: '',
        SubCategory:'',
      },
      sortType: '',
      sortField: '',
    };
  }

  /** open popup for create task
   * @param data
   */
  openDialog(data) {
    this.blnCreateTaskpopupClose = false;
    const ref = this.dialogService.open(CtlsCreateEditTaskPopupComponent, {
      width: '65vw',
      height: '70vh',
      transitionOptions: '0ms',
      data: data,
    });

    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((objSaveTask) => {
      this.blnCreateTaskpopupClose = true;
      this.popupMessageService.clearMessage(); //when close clear popup data in service
    });
  }

  /** open popup for view survey details
   * @param data
   */
  openViewDialog(data) {
    data.surveyType = this.selectedRadioOption === 1 ? 1 : 2;
    this.blnCreateTaskpopupClose = false;
    const ref = this.dialogService.open(CtlsViewSurveyDetailsComponent, {
      width: '80%',
      height: '70vh',
      transitionOptions: '0ms',
      data: data,
    });

    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((objSaveTask) => {
      this.blnCreateTaskpopupClose = true;
      this.popupMessageService.clearMessage(); //when close clear popup data in service
    });
  }
  /** open popup for bulk update
   */
  showBulkUpdate() {
    const ref = this.dialogService.open(CtlsBulkUpdatePopupComponent, {
      width: '30vw',
      height: '40vh',
      transitionOptions: '0ms',
      header: 'Upload your file',
      styleClass: 'dynamic-table-style',
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(() => { });
  }
  /**
   * move the selected row up or down the sequence
   * @param dataObj param for API
   */
  modifyRowSequence(dataObj, seqType) {
    if (!!dataObj && dataObj.length) {
      this.spinnerService.show();
      let saveObj = {
        SequenceDetailsList: dataObj,
        IsDesc: seqType === 'DOWN' ? true : false,
        SequenceCount: this.rawTableData.sequencedLength,
        IsTrans: this.selectedRadioOption === 1 ? true : false
      };
      this.ctlsTaskListingService
        .SaveModifiedSequence(saveObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.spinnerService.hide();
          this.ref.detectChanges();
          this.sequenceModifiedResponse = res;
          if (res.Response && res.Status == 'SUCCESS') {
            if (!!res.Response && res.Response.length)
              this.handleSequenceResponse(res.Response, seqType);
            else return;
          }
        });
    }
  }
  /**
   * function sequenceces the data according to response
   * @param response
   * @param seqType
   * Swapping the item at sequence index with the item at old index.
   * scenario 1 : change sequence of sequenced items by checking if sequence is < this.maxSeqNum ie 15
   * scenario 2 : change sequence of sequenced items to null if sequence is > this.maxSeqNum ie 15
   * scenario 3 : change sequence of changed items to null if index is > this.maxSeqNum ie 15
   * scenario 4 : change sequence of changed items to OldIndex if index is < this.maxSeqNum ie 15 and
   * already had its own sequence
=   */
  handleSequenceResponse(sequencedId, seqType) {
    if (!!sequencedId && sequencedId.length) {
      let lastSequencedIndex = _.findLastIndex(
        this.rawTableData.Items,
        (item) => typeof item.Sequence === 'number'
      );

      if (seqType === 'DOWN' && sequencedId[0].Sequence > lastSequencedIndex)
        //checking if new sequenced are being added, if so sort it in asec order.
        sequencedId = _.orderBy(sequencedId, ['Sequence'], ['asc']);

      sequencedId.map((item) => {
        [
          this.rawTableData.Items[item.OldIndex],
          this.rawTableData.Items[item.Sequence]
        ] = [
            this.rawTableData.Items[item.Sequence],
            this.rawTableData.Items[item.OldIndex],
          ];
        if (this.rawTableData.IsFiltered) {
          let oldseq = null;
          if (item.FilteredSequence < this.maxSeqNum) {
            //scenario 1;
            oldseq = this.rawTableData.Items[item.Sequence].Sequence
            this.rawTableData.Items[item.Sequence].Sequence = item.FilteredSequence;
          }
          //scenario 2
          else this.rawTableData.Items[item.Sequence].Sequence = null;
          if (item.OldIndex >= this.maxSeqNum)
            //scenario 3
            this.rawTableData.Items[item.OldIndex].Sequence = null;
          if (
            item.OldIndex < this.maxSeqNum &&
            typeof this.rawTableData.Items[item.OldIndex].Sequence === 'number' &&
            typeof oldseq === 'number'
          )
            //scenario 4
            this.rawTableData.Items[item.OldIndex].Sequence = oldseq;
        } else {
          if (item.Sequence < this.maxSeqNum)
            //scenario 1
            this.rawTableData.Items[item.Sequence].Sequence = item.Sequence;
          //scenario 2
          else this.rawTableData.Items[item.Sequence].Sequence = null;
          if (item.OldIndex >= this.maxSeqNum)
            //scenario 3
            this.rawTableData.Items[item.OldIndex].Sequence = null;
          if (
            item.OldIndex < this.maxSeqNum &&
            typeof this.rawTableData.Items[item.OldIndex].Sequence === 'number'
          )
            //scenario 4
            this.rawTableData.Items[item.OldIndex].Sequence = item.OldIndex;
        }

        if (!!item.sequencedLength) {
          this.rawTableData.sequencedLength = item.sequencedLength;
        }
      });
      this.modifyResponseWithSelectedCard(
        this.rawTableData,
        this.filterObj.filterFields.selectedCard
      );
    }
  }
  /**
  * function returns the sequence array for API
  * @param seqType : string = DOWN or UP
  * @param selectedRows : rows selected from presentation
  */
  rowSequencing(seqType, selectedRows) {
    let sequencedId = [];
    if (!!selectedRows && selectedRows.length) {
      let index = null;
      let lastSequencedIndex = -1;
      lastSequencedIndex = _.findLastIndex(this.rawTableData.Items, (item) => typeof item.Sequence === 'number');
      let selectedRowLength = selectedRows.length;
      let orderedRows = [];
      let seqArray = [];
      seqArray = _.filter(selectedRows, (item) => typeof item.Sequence === 'number');
      let IsFilterApplied = false;
      let filteredLastSequencedIndex = -1;

      // order the selected rows according to the sequence type, descending for DOWN
      //  and according to same order as present in the table/ascending for UP.
      if (seqType === 'DOWN' && seqArray.length != 0)
        orderedRows = _.orderBy(selectedRows, ['Sequence'], ['desc']);
      else {
        orderedRows = _.filter(this.rawTableData.Items, (item) =>
          _.find(selectedRows, (element) => item.TaskID === element.TaskID)
        );
      }
      if (this.rawTableData.IsFiltered) {
        IsFilterApplied = true;
        if (this.rawTableData.sequencedLength != 0)
          //index is always its length - 1
          filteredLastSequencedIndex = this.rawTableData.sequencedLength - 1;
      }

      orderedRows.map((item) => {
        let obj = null;
        let seqObj = null;
        let seqItem = null;
        let changedItem = null; // item that is added in sequencedId with same sequence as current item.

        index = _.findIndex(this.rawTableData.Items, (element) => (item.TaskID === element.TaskID));

        // sequencing items that is already sequenced byt one step up or down
        if (typeof item.Sequence === 'number') {
          if (
            seqType === 'DOWN' &&
            index < lastSequencedIndex &&
            index < this.rawTableData.Items.length - 1
          ) {
            obj = {
              CTLSTaskListID: item.TaskID,
              Sequence: index + 1,
              OldIndex: index,
              IschangedManual: true,
              IsFilterApplied: IsFilterApplied,
              FilteredSequence: this.rawTableData.Items[index + 1].Sequence
            };
            sequencedId.push(obj);
            changedItem = _.find(sequencedId, (seq) => seq.Sequence === obj.Sequence && seq.CTLSTaskListID != item.TaskID);
            //  in sequencing consecutive rows changed index of one will be the new sequence
            //  of other, to capture this we are checking the sequenced index of already sequenced ones
            //  in sequencedId.
            seqItem = changedItem ? changedItem : this.rawTableData.Items[index + 1];
            if (!!changedItem) {
              seqObj = {
                CTLSTaskListID: seqItem.CTLSTaskListID,
                Sequence: index,
                OldIndex: index + 1,
                IschangedManual: false,
                IsFilterApplied: IsFilterApplied,
                FilteredSequence: this.rawTableData.Items[index].Sequence
              };
            } else {
              seqObj = {
                CTLSTaskListID: seqItem.TaskID,
                Sequence: index,
                OldIndex: index + 1,
                IschangedManual: false,
                IsFilterApplied: IsFilterApplied,
                FilteredSequence: this.rawTableData.Items[index].Sequence
              };
            }
            sequencedId.push(seqObj);
          } else if (seqType === 'UP' && index > 0) {
            obj = {
              CTLSTaskListID: item.TaskID,
              Sequence: index - 1,
              OldIndex: index,
              IschangedManual: true,
              IsFilterApplied: IsFilterApplied,
              FilteredSequence: this.rawTableData.Items[index - 1].Sequence
            };
            sequencedId.push(obj);
            changedItem = _.find(sequencedId, (seq) => seq.Sequence === obj.Sequence && seq.CTLSTaskListID != item.TaskID);
            //  in sequencing consecutive rows changed index of one will be the new sequence
            //  of other, to capture this we are checking the sequenced index of already sequenced ones
            //  in sequencedId.
            seqItem = changedItem ? changedItem : this.rawTableData.Items[index - 1];
            if (!!changedItem) {
              seqObj = {
                CTLSTaskListID: seqItem.CTLSTaskListID,
                Sequence: index,
                OldIndex: index - 1,
                IschangedManual: false,
                IsFilterApplied: IsFilterApplied,
                FilteredSequence: this.rawTableData.Items[index].Sequence
              };
            } else {
              seqObj = {
                CTLSTaskListID: seqItem.TaskID,
                Sequence: index,
                OldIndex: index - 1,
                IschangedManual: false,
                IsFilterApplied: IsFilterApplied,
                FilteredSequence: this.rawTableData.Items[index].Sequence
              };
            }
            sequencedId.push(seqObj);
          }
        } else {
          //  for newely sequenced rows, sequencing them from their position straight to
          //  top 15, with the help of lastSequencedIndex.
          if (
            (lastSequencedIndex < 0 && 14 < selectedRowLength) ||
            (lastSequencedIndex === 0 && !sequencedId.length && 14 < selectedRowLength) ||
            (lastSequencedIndex > 0 && !sequencedId.length && 14 - lastSequencedIndex < selectedRowLength) ||
            (lastSequencedIndex > 0 && sequencedId.length && 14 - lastSequencedIndex < selectedRowLength - sequencedId.length)
          ) {
            //  if newwly selected for sequencing count is greater than the empty sequencing
            //  spaces left, reducing lastSequencedIndex accordingly.
            lastSequencedIndex = lastSequencedIndex - (selectedRowLength - (14 - lastSequencedIndex));
          }
          //in cas if table is filtered, then used filtered index and manipulate it accordingly
          if (
            (filteredLastSequencedIndex < 0 && 14 < selectedRowLength) ||
            (filteredLastSequencedIndex === 0 && !sequencedId.length && 14 < selectedRowLength) ||
            (filteredLastSequencedIndex > 0 && !sequencedId.length && 14 - filteredLastSequencedIndex < selectedRowLength) ||
            (filteredLastSequencedIndex > 0 && sequencedId.length && 14 - filteredLastSequencedIndex < selectedRowLength - sequencedId.length)
          ) {
            filteredLastSequencedIndex = filteredLastSequencedIndex - (selectedRowLength - (14 - filteredLastSequencedIndex));
          }
          if (
            (seqType === 'DOWN' || seqType === 'UP') &&
            lastSequencedIndex < 15
          ) {
            obj = {
              CTLSTaskListID: item.TaskID,
              Sequence: lastSequencedIndex < 0 ? 0 : lastSequencedIndex + 1,
              OldIndex: index,
              IschangedManual: true,
              IsFilterApplied: IsFilterApplied,
              FilteredSequence: filteredLastSequencedIndex < 0 ? 0 : filteredLastSequencedIndex + 1
            };
            sequencedId.push(obj);
            lastSequencedIndex = lastSequencedIndex + 1;
            filteredLastSequencedIndex = filteredLastSequencedIndex + 1;
          }
        }
      });
    }
    return sequencedId;
  }

  /**
    * For unpinning the selected row
    * @param TaskId param for API
    */
  unpinTask(TaskIds, isOnlyUnpin) {
    if (!!TaskIds) {
      this.spinnerService.show();
      this.ctlsTaskListingService
        .unpinTaskSequence(TaskIds, this.selectedRadioOption === 1 ? true : false)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.spinnerService.hide();
          if (res.Response && res.Status == 'SUCCESS') {
          TaskIds.TaskListIDs.forEach((taskId) => {
            this.handleUnpinResponse(taskId, isOnlyUnpin);
          })
          }
        })
    }
  }

  pinUnpinTask(data){
    const unpinTaskIds: number[] = data.pinRecords.map((item) => item.TaskID);
    this.spinnerService.show();

    if (unpinTaskIds.length > 0) {
      this.ctlsTaskListingService
        .unpinTaskSequence(unpinTaskIds, this.selectedRadioOption === 1 ? true : false)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res.Response && res.Status == 'SUCCESS') {
            if(data.unPinRecords.length > 0){
              this.performPinOperation(data, unpinTaskIds)
            }else{
              this.updateTaskRecords(unpinTaskIds);
            }
          }else{
            this.spinnerService.hide();
          }
        })
    }else{
      this.performPinOperation(data, unpinTaskIds)
    }
  }

  performPinOperation(data, unPinTaskIds){
    let pinRecords = this.rawTableData.Items.filter((item) => typeof item.Sequence === 'number');
    pinRecords.sort((a, b) => a.Sequence - b.Sequence);
    const lastIndex = 
    pinRecords.length > 0 && 
    pinRecords[pinRecords.length - 1] && 
    typeof pinRecords[pinRecords.length - 1].Sequence === 'number' 
        ? (pinRecords[pinRecords.length - 1].Sequence - unPinTaskIds.length) + 1 
        : 0;

    const dataObj = data.unPinRecords.map((item, i) => ({
      CTLSTaskListID: item.TaskID,
      Sequence: lastIndex + i,
      OldIndex: lastIndex + i,
      IschangedManual: true,
      IsFilterApplied: true,
      FilteredSequence: lastIndex + i,
    }));

    if (dataObj.length > 0) {
      let saveObj = {
        SequenceDetailsList: dataObj,
        IsDesc: true,
        SequenceCount: pinRecords.length,
        IsTrans: this.selectedRadioOption === 1 ? true : false
      };
      this.ctlsTaskListingService
        .SaveModifiedSequence(saveObj)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.updateTaskRecords(unPinTaskIds, dataObj);
      });
    }
  }

  updateTaskRecords(removeSequence?, addSequence?){
    if (removeSequence && removeSequence.length) {
      removeSequence.forEach((taskID) => {
        const item = this.rawTableData.Items.find((item) => item.TaskID === taskID);
        if (item) {
          item.Sequence = null;
          this.rawTableData.sequencedLength -= 1;
        }
      });
    }
  
    if (addSequence && addSequence.length) {
      addSequence.forEach((task) => {
        const item = this.rawTableData.Items.find((item) => item.TaskID === task.CTLSTaskListID);
        if (item) {
          item.Sequence = task.Sequence;
          item.OldIndex = task.OldIndex;
          item.IschangedManual = task.IschangedManual;
          item.IsFilterApplied = task.IsFilterApplied;
          item.FilteredSequence = task.FilteredSequence;

          this.rawTableData.sequencedLength += 1;
        }
      });
    }
      
    this.rawTableData.Items.sort((a, b) => {
      if (a.Sequence == null && b.Sequence == null) {
        return 0;
      }
      if (a.Sequence == null) {
        return 1;
      }
      if (b.Sequence == null) {
        return -1;
      }
      return a.Sequence - b.Sequence;
    });
    
    this.spinnerService.hide();
    this.modifyResponseWithSelectedCard(
      this.rawTableData,
      this.filterObj.filterFields.selectedCard
    );
  }

  /**
    * For handling the unpinning API response
    * @param TaskId
    */
  handleUnpinResponse(TaskId, isOnlyUnpin: boolean) {
    if (TaskId) {
      let lastSequencedIndex = _.findLastIndex(this.rawTableData.Items, (item) => (typeof item.Sequence === 'number'));
      let index = _.findIndex(this.rawTableData.Items, (element) => (TaskId === element.TaskID));
      this.rawTableData.Items.splice(lastSequencedIndex + 1, 0, this.rawTableData.Items[index]);
      this.rawTableData.Items.splice(index, 1);
      this.rawTableData.Items[lastSequencedIndex].Sequence = null;

      for (let i = index; i < lastSequencedIndex; i++) {
        this.rawTableData.Items[i].Sequence = i;
      }
      if (this.rawTableData.sequencedLength > 0 && isOnlyUnpin)
        this.rawTableData.sequencedLength = this.rawTableData.sequencedLength - 1;

      this.modifyResponseWithSelectedCard(
        this.rawTableData,
        this.filterObj.filterFields.selectedCard
      );
    }
    this.ref.detectChanges();
  }
}
