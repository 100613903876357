export const environment = {
  production: true,
  baseUrl: 'https://api.cemfalcon.com/',
  baseUrlNode: 'https://api.cemfalcon.com:3000/',
  baseUrlAuth: 'https://app-10007097-falcon-authenticate-api-prod-eqachpf8f9fsbwgt.westeurope-01.azurewebsites.net/api/v1',
  loginUrl: 'https://loyalty.cemfalcon.com/auth/login',
  changePasswordUrl: 'https://loyalty.cemfalcon.com/auth/change-password',
  accessDeniedUrl: "error/accessdenied",
  apiUrl: "https://api.cemfalcon.com:10001",
  identityUrl: "https://api.cemfalcon.com:11000",
  ctlsApiUrl: "https://api.cemfalcon.com:12001",
  dashboardImagePath: "https://cemfalcon.blob.core.windows.net/site-assets/dashboard/",
  containerName: "falconimguploadlocal",
  dragDropTimeOut: 5000,
  CustomerSupportEmailID: 'ACFalcon-admin-prod@thinkpalm.com',
  Field:{
    OpCountryId:"FAMOperationalCountryID"
  },
  websocket:{
    hostURL:'wss://api.cemfalcon.com:3000'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmFsY29ucHJvZGFkbWluIiwiYSI6ImNscDgxMm56NzIyb3oycHF1NXZvbWJ6Y3IifQ.OZ_fMHnZXV7FTVU8GFxlkg' // Prod access token goes here
  },
  imagepath:'https://loyalty.cemfalcon.com/assets/images/',
  assetpath:'https://loyalty.cemfalcon.com/assets/',
  activeDirectory: {
    config: {
      auth: {
        clientId :"53a60a3f-b821-4b89-a445-2361bf66c61d",
        authority: "https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9/"
      }
    }
  }
};
